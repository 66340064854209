
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$partner-program-admin-frontend-primary: mat.define-palette(mat.$blue-palette);
$partner-program-admin-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$partner-program-admin-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$partner-program-admin-frontend-theme: mat.define-light-theme((
  color: (
    primary: $partner-program-admin-frontend-primary,
    accent: $partner-program-admin-frontend-accent,
    warn: $partner-program-admin-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($partner-program-admin-frontend-theme);

/* You can add global styles to this file, and also import other style files */

:root {
	/* COLORS */
	--color-black: #000000;
	--color-white: #ffffff;

	--color-gray-100: #f6f6f9;
	--color-gray-200: #f1f1f3;
	--color-gray-300: #e2e8f0;
	--color-gray-400: #a0aec0;
	--color-gray-500: #2d3748;
	--color-gray-600: #222428;

	--color-red-100: #fff5f5;
	--color-red-200: #feb2b2;
	--color-red-300: #f56565;
	--color-red-400: #9b2c2c;
	--color-red-500: #63171b;

	--color-orange-100: #fffaf0;
	--color-orange-200: #fbd38d;
	--color-orange-300: #ff9114;
	--color-orange-400: #9c4221;
	--color-orange-500: #652b19;

	--color-green-100: #f0fff4;
	--color-green-200: #9ae6b4;
	--color-green-300: #48bb78;
	--color-green-400: #276749;
	--color-green-500: #1c4532;

	--color-blue-100: #ebf8ff;
	--color-blue-200: #90cdf4;
	--color-blue-300: #0098fc;
	--color-blue-400: #2c5282;
	--color-blue-500: #1a365d;
	--color-blue-600: #142b48;

	/* FONT-SIZE */
	--font-size-xs: 0.75rem;
	--font-size-sm: 0.875rem;
	--font-size-md: 1rem;
	--font-size-lg: 1.125rem;
	--font-size-xl: 1.25rem;
	--font-size-2xl: 1.5rem;
	--font-size-3xl: 1.875rem;

	/* LINE-HEIGHT */
	--line-height-xs: 1;
	--line-height-md: 1.5;
	--line-height-lg: 2;

	/* BREAKPOINTS */
	--breakpoints-sm: 30em;
	--breakpoints-md: 48em;
	--breakpoints-lg: 62em;
	--breakpoints-xl: 80em;

	/* SPACE */
	--space-xs: 0.5rem;
	--space-sm: 1rem;
	--space-md: 1.5rem;
	--space-lg: 2rem;
	--space-xl: 2.5rem;

	/* SIZES */
	--size-sm: 2rem;
	--size-md: 3rem;
	--size-lg: 4rem;

	/* BORDER-RADIUS */
	--radius-base: 0.25rem;
	--radius-full: 50%;

	/* Z-INDEX */
	--zindex-hide: -1;
	--zindex-dropdown: 1000;
	--zindex-sticky: 1100;
	--zindex-banner: 1200;
	--zindex-overlay: 1300;
	--zindex-modal: 1400;
	--zindex-popover: 1500;
	--zindex-toast: 1700;
	--zindex-tooltip: 1800;

	/* SIZES */
	--shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
		rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
		rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
		rgb(60 66 87 / 8%) 0px 2px 5px 0px;

	/* FONT-FAMILY */
	--font-family: 'Inter', sans-serif;
}

.input{
	background-color: var(--color-white);
	width: 100%;
	height: 40px;
	border: 1px solid var(--color-gray-300);
	border-radius: var(--radius-base);
	padding: 0 1rem;
	font-size: var(--font-size-md);
	color: var(--color-gray-500);
	outline: none;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
